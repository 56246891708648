<template>
  <div class="box has-max-width">
    <Heading size="5" :text="$t('profile.settings.email.title')" />
    <Paragraph :text="$t('profile.settings.email.helpText')" />

    <Form @submit="submit">
      <FormField :label="$t('profile.settings.email.currentEmail')">
        {{ user.email }}
      </FormField>

      <FormField :label="$t('profile.settings.email.newEmail')">
        <EmailInputField
          v-model="newEmail"
          :disabled="isSubmitting"
          :valid="!emailExists"
          :placeholder="$t('profile.settings.email.emailAddress')">
        </EmailInputField>

        <HelpText
          v-if="emailExists"
          type="danger"
          :text="$t('profile.settings.email.busyEmail')">
        </HelpText>
      </FormField>

      <FormField>
        <Button
          type="primary"
          :disabled="newEmail.length === 0"
          :loading="isSubmitting"
          :title="$t('profile.settings.email.changeEmail')"
          @click="submit">
        </Button>
      </FormField>
    </Form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const STATUS_DEFAULT = ''
const STATUS_SUBMITTING = 'submitting'
const STATUS_EMAIL_EXISTS = 'email-exists'

export default {
  data () {
    return {
      status: STATUS_DEFAULT,
      newEmail: ''
    }
  },

  computed: {
    ...mapGetters({
      user: 'user/data'
    }),

    isSubmitting () {
      return this.status === STATUS_SUBMITTING
    },

    emailExists () {
      return this.status === STATUS_EMAIL_EXISTS
    }
  },

  methods: {
    async submit () {
      this.status = STATUS_SUBMITTING

      try {
        await this.$store.dispatch('user/changeEmail', {
          userId: this.user.id,
          email: this.newEmail
        })

        this.$store.dispatch('auth/setEmail', this.newEmail)
        this.$notification.success(this.$i18n.t('profile.settings.email.emailChangedMessage', { email: this.newEmail }))

        this.newEmail = ''
        this.status = STATUS_DEFAULT
      } catch (error) {
        if (error.toString().includes('400')) {
          this.status = STATUS_EMAIL_EXISTS
        } else {
          this.status = STATUS_DEFAULT

          this.$notification.danger(this.$i18n.t('general.errorOccured'))
          console.error(error)
        }
      }
    }
  }
}
</script>
